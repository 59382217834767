<template>
  <div>
    <vx-card title="حدث خطأ" class="mb-2" title-color="danger" v-if="Object.keys(errors_values).length">

      <div v-for="(key, index) in Object.keys(errors_values)" :key="index">

        <span v-for="(error, index) in errors_values[key]" :key="index" class="text-danger">
          {{ error }}
        </span>
      </div>

    </vx-card>
    <vx-card title="إضافة طلبية جديدة" title-color="primary">


      <div class="vx-row">

        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">للمستخدم</label>
          <v-select   v-model="user_id" name="to-the-user" v-validate="'required'" data-vv-as="للمستخدم" label="phone"
            :reduce="(item) => item.id" :options="users">
            <template #option="{ username }">
              <p>{{ username }}</p>
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('order-items.to-the-user')">{{
          errors.first("order-items.to-the-user") }}</span>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label mt-5">تاريخ التوصيل</label>
          <datepicker class="w-full" format="dd/MM/yyyy" v-validate="'date_format:dd/MM/yyyy'"
            data-vv-as="تاريخ التوصيل" name="delivery_date" v-model="delivery_date" tabindex="2"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('delivery_date')">{{ errors.first("delivery_date")
          }}</span>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-row">

            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">فترة التوصيل من</label>
              <div>
                <vue-timepicker style="width:100%;" name="من الفترة" dir="ltr" v-model="time_from" format="HH:mm">
                </vue-timepicker>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('من الفترة')">{{ errors.first("من الفترة") }}</span>
            </div>

            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">فترة التوصيل الي</label>
              <div>
                <vue-timepicker style="width:100%;" name="الي الفترة" dir="ltr" v-model="time_to" format="HH:mm">
                </vue-timepicker>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('الي الفترة')">{{ errors.first("الي الفترة")
              }}</span>
            </div>

          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label mt-5">وسيلة الدفع</label>
          <v-select v-model="payment_method_id" name="payment_method" v-validate="'required'" data-vv-as="وسيلة الدفع"
            label="lable" :reduce="(item) => item.value" :options="paymentMethods"></v-select>
          <span class="text-danger text-sm" v-show="errors.has('payment_method')">{{ errors.first("payment_method")
          }}</span>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">استخدام كوبون</label>
          <v-select v-model="coupon" name="coupon" label="code" :options="coupons"></v-select>
        </div>

        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label mt-5">سعر الدعم</label>
          <vs-input data-vv-as="سعرالدعم" v-validate="'numeric|min:0'" type="number" class="w-full" name="support"
            v-model="support" />
          <span class="text-danger text-sm" v-show="errors.has('support')">{{ errors.first("support") }}</span>
        </div>


        <div class="vx-col md:w-1/1 w-full mb-2">
          <label class="vs-input--label mt-5">ملاحظة</label>
          <vs-input data-vv-as="ملاحظة" v-validate="''" type="text" class="w-full" name="note" v-model="note" />
          <span class="text-danger text-sm" v-show="errors.has('note')">{{ errors.first("note") }}</span>
        </div>







      </div>

      <div v-if="isNewUser" class="new-user-form">
        <vs-divider></vs-divider>
        <div class="vx-row">

          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">أسم المستخدم</label>
            <vs-input name="اسم المستخدم" v-validate="'required'" v-model="username" style="width:100%" class="w-100"
              placeholder="احمد" />
            <span class="text-danger text-sm" v-show="errors.has('اسم المستخدم')">{{ errors.first("اسم المستخدم")
            }}</span>
          </div>

          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label mt-5">رقم الهاتف</label>
            <vs-input name="رقم الهاتف" v-validate="'required'" v-model="phone" style="width:100%" class="w-100"
              placeholder="09XXXXXXXX" />

            <span class="text-danger text-sm" v-show="errors.has('رقم الهاتف')">{{ errors.first("رقم الهاتف") }}</span>
          </div>

          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label mt-5">كلمة المرور</label>
            <vs-input v-validate="'required|min:8'" name="كلمة المرور" v-model="password" style="width:100%"
              class="w-100" placeholder="***********" />
            <span class="text-danger text-sm" v-show="errors.has('كلمة المرور')">{{ errors.first("كلمة المرور")
            }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label mt-5">العنوان </label>
            <vs-input v-validate="'required'" name="العنوان" v-model="address" style="width:100%" class="w-100"
              placeholder="العنوان" />
            <span class="text-danger text-sm" v-show="errors.has('العنوان')">{{ errors.first("العنوان") }}</span>
          </div>



        </div>
      </div>


      <vs-divider></vs-divider>


      <div class="vx-row mt-5 mb-5">
        <div class="vx-col w-full mb-2" style="z-index:1;">
          <vs-table :data="order_item_list" v-if="order_item_list.length">
            <template slot="thead">
              <vs-th>إسم المنتج او الباقة</vs-th>
              <vs-th>السعر</vs-th>
              <vs-th>الكمية المحددة</vs-th>
              <vs-th>المجموع</vs-th>
              <vs-th>العمليات</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">
                  {{ data[indextr].name }}
                </vs-td>
                <vs-td :data="data[indextr].price">
                  {{ data[indextr].price }}
                </vs-td>

                <vs-td>
                  <vs-input :disabled="data[indextr].package" v-validate="{
                    required: data[indextr].product ? true : false,
                    max_value: data[indextr].product_quantity,
                  }" type="number" name="quantity" v-model="data[indextr].quantity" class="w-1/2" />
                  <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first("quantity")
                  }}</span>
                </vs-td>
                <vs-td>
                  {{
                  data[indextr].price * data[indextr].quantity ||
                  data[indextr].price
                  }}
                </vs-td>
                <vs-td>
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                    @click.stop="deleteItem(indextr)" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-table class="w-1/2" data="order_item_list" v-if="order_item_list.length">
            <template>
              <vs-tr>
                <vs-th>مجموع الطلبية</vs-th>
                <vs-td> {{ sub_total }} د.ل</vs-td>
              </vs-tr>
              <vs-tr v-if="coupon.value!=0">
                <vs-th>قيمة الكوبون</vs-th>
                <vs-td> {{ coupon.value }} د.ل</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>التخفيض</vs-th>
                <vs-td>
                  <vs-input type="number" class="w-1/3" name="discount" v-model="discount" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

      <div class="vx-row mt-2 w-full align-items-center">
        <div class="vx-col my-3 sm:w-1/2 w-full mb-2 flex">
          <label class="vs-input--label"> اختر رقم sku</label>
          <v-select name="sku" class="w-full" data-vv-as="sku" data-vv-scope="order-items" label="sku"
            :options="products" @input="chooseProduct" :tabindex="4" :filterable="true"></v-select>
        </div>
        <div class="vx-col mt-3 sm:w-1/2 w-full mb-6 seperator flex">
          <label class="vs-input--label">اختر الباقة</label>
          <v-select class="w-full" name="chosen_package" data-vv-as="الباقة" label="name" :options="packages"
            @input="choosePackage" :tabindex="4" :filterable="true"></v-select>
        </div>
      </div>

      <div class="vx-row mt-8">
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-button class="mr-5 text-lg px-16 py-4" @click="submit" color="success" type="filled">حفظ التعديلات
          </vs-button>
          <vs-button class="mr-5 text-lg py-4" @click="$router.go(-1)" color="warning" type="border">إلغاء</vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>

import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moduleOrder from "@/store/orders/moduleOrder.js";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  components: { vSelect, Datepicker, VueTimepicker },
  data() {
    return {
      time_from: "",
      time_to: "",
      username: "",
      phone: "",
      password: "",
      address: "",
      discount: 0,

      note: '',
      support: 0,
      order_status_id: "",
      delivery_date: "",
      user_id: "",
      coupon: {
        code: '',
        value: 0,
      },
      payment_method_id: "",
      product: {
        product: "",
        name: "",
        price: "",
        new_price: "",
        product_quantity: "",
        quantity: 1,
      },
      chosen_package: {
        package: "",
        name: "",
        price: "",
      },
      order_item_list: [],
      errors_values: {}

    };
  },
  computed: {

    isNewUser() {




      if (this.user_id === -1) return true;
      return false;
    },

    users() {

      let users = this.$store.state.users
      let UsersPlus = [{ username: "اضافة مستخدم جديد", id: -1 }]

      return UsersPlus.concat(users);
    },
    products() {
      return this.$store.state.products;
    },
    packages() {
      return this.$store.state.packages;
    },
    coupons() {
      return this.$store.state.coupons;
    },
    paymentMethods() {
      return this.$store.state.order.paymentMethods;
    },
    sub_total() {
      return this.total - this.discount - this.coupon.value;
    },
    total() {
      let orders_total_amount = this.order_item_list.reduce(function (s, e) {
        return (
          s +
          (parseFloat(e.price)
            ? parseFloat(e.price) *
            (parseFloat(e.quantity) ? parseFloat(e.quantity) : 1)
            : 0)
        );
      }, 0);
      return orders_total_amount;
    },
  },
  methods: {
    chooseProduct(product) {
      this.product.product = product.id;
      this.product.name = product.name;
      this.product.price =
        product.new_price > 0 ? product.new_price : product.price;
      this.product.product_quantity = product.quantity;
      this.order_item_list.push(this.product);
      this.product = {
        product: "",
        name: "",
        price: "",
        product_quantity: "",
        quantity: 1,
      };
    },
    choosePackage(packageObject) {
      this.chosen_package.package = packageObject.id;
      this.chosen_package.name = packageObject.name;
      this.chosen_package.price = packageObject.total;
      this.order_item_list.push(this.chosen_package);
      this.chosen_package = {};
    },
    async submit() {


      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });
      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }
      if (this.order_item_list.length < 1) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى إضافة منتج علي الأقل",
          color: "danger",
        });
        return false;
      }

      let formdata = new FormData();
      formdata.set("total", this.total);
      formdata.set("discount", this.discount);
      formdata.set("delivery_date", this.formatDate(this.delivery_date));
      formdata.set("note", this.note);
      formdata.set("support", this.support);

      formdata.set("payment_method", this.payment_method_id);

      formdata.set("time_from", this.time_from.toString());
      formdata.set("time_to", this.time_to.toString());


      formdata.set("status", 1);

      if (this.isNewUser) {




        formdata.set("username", this.username);
        formdata.set("phone", this.phone);
        formdata.set("password", this.password);
        formdata.set("address", this.address);
        formdata.set("roles[0]", 1);

      } else {
        formdata.set("user", this.user_id);
      }



      formdata.set("coupon", this.coupon.code);
      formdata.set("items", JSON.stringify(this.order_item_list));

      this.$store
        .dispatch(this.isNewUser ? "order/createNewOrderbyNewUser" : "order/createNewOrder", formdata)
        .then(() => {
          this.$vs.notify({
            title: "نجاح",
            text: "تمت إنشاء الطلب",
            color: "success",
          });
          this.$router.push({ name: "orders" });
        })
        .catch((err) => {
          this.errors_values = err.response.data.errors;
          this.$vs.notify({
            title: "حدث خطأ",
            text: err.response.data.message,
            color: "danger",
          });
        });
    },

    async NewUserSubmit() {

      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });
      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }
      if (this.order_item_list.length < 1) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى إضافة منتج علي الأقل",
          color: "danger",
        });
        return false;
      }

      let formdata = new FormData();
      formdata.set("total", this.total);
      formdata.set("discount", this.discount);
      formdata.set("delivery_date", this.formatDate(this.delivery_date));
      formdata.set("payment_method", this.payment_method_id);
      formdata.set("status", 1);
      formdata.set("coupon", this.coupon.code);
      formdata.set("items", JSON.stringify(this.order_item_list));

      formdata.set("note", this.note);
      formdata.set("support", this.support);

      formdata.set("username", this.username);
      formdata.set("phone", this.phone);
      // formdata.set("address", this.address);
      formdata.set("password", this.password);


      this.$store
        .dispatch("order/createNewOrderbyNewUser", formdata)
        .then(() => {
          this.$vs.notify({
            title: "نجاح",
            text: "تمت إنشاء الطلب",
            color: "success",
          });
          this.$router.push({ name: "orders" });
        })
        .catch((err) => {
          this.errors_values = err.response.data.errors;
          this.$vs.notify({
            title: "حدث خطأ",
            text: err.response.data.message,
            color: "danger",
          });
        });
    },

    deleteItem(index) {
      this.order_item_list.splice(index, 1)
    },
  },
  created() {
    if (!moduleOrder.isRegistered) {
      this.$store.registerModule("order", moduleOrder);
      moduleOrder.isRegistered = true;
    }
    this.$store.dispatch("getAllUsers");
    this.$store.dispatch("getAllProducts");
    this.$store.dispatch("getAllPackages");
    this.$store.dispatch("getAllCoupons");
    this.$store.dispatch("order/getPaymentMethods");

  },
};
</script>

<style>
.display-time {
  width: 100% !important;
}

.seperator {
  border-left: 1px solid rgb(204, 204, 204);
}

.align-items-center {
  align-items: center;
}
</style>
